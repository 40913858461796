<template>
  <div class="resetPassword">
    <div class="centerContent">
      <div class="formContainer">
        <div v-if="feedback === ''">
          <div class="formTop flex items-center flex-col">
            <img src="/images/logo-dark.png" class="mb-15 w-130" alt="Logo" />
            <h2>Forget Password</h2>
          </div>
          <div class="formBody">
            <p class="text-center font-size-15 line-h-20">
              Enter your email address and we'll send you a link to help you
              reset your password.
            </p>
            <div class="form">
              <!-- email -->
              <div class="input-group mb-15">
                <label>Email</label>

                <input
                  type="email"
                  v-model="email"
                  required
                  class="fullWidth"
                  @keyup.enter="triggerSumbit"
                />
              </div>
              <a-button
                type="primary h-50 w-full"
                :disabled="!email_valid"
                @click.prevent="resetPassword"
              >
                SUBMIT
              </a-button>

              <div class="formBody__signup">
                <router-link :to="{ name: 'login' }" class="blueTextLink"
                  >Cancel</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <!-- form feedback -->
        <div
          class="feedback"
          :class="{ expand: feedback !== '' }"
          v-html="feedback"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";

export default {
  data() {
    return {
      email: "",
      feedback: "",
      email_valid: false,
    };
  },
  computed: {
    formValid() {
      return this.email_valid;
    },
  },
  watch: {
    email() {
      this.validateEmail();
    },
  },
  methods: {
    validateEmail() {
      const emailFormat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.email_valid = emailFormat.test(this.email);
    },
    triggerSumbit() {
      if (this.email_valid) {
        this.resetPassword();
      }
    },
    resetPassword() {
      new Promise((resolve) => {
        wsUtils.ForgotPassword(resolve, {
          EmailId: this.email,
        });
      });

      this.restComplete();
    },
    restComplete() {
      this.feedback =
        '<h2>Thank You</h2><p>If the email entered exists in our database, an email will be sent to help you reset your password.</p><p>Please check your inbox <span class="note">(or sometimes junk folder)</span> and follow the instructions provided in order to set up a new password.</p><p class="note">For your security, the reset link can only be used once and will expire in 10 minutes. If you encounter any problems, please contact support.</p>';
    },
    backLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.resetPassword {
  background: var(
    --button-gradient,
    linear-gradient(99deg, #fb3b51 -11.27%, #a14be7 109.61%)
  );
  height: 100%;
  background-attachment: fixed;
  display: grid;
}

.form {
  margin: 2em 0;
}

.failBG {
  margin-bottom: 1em;
  padding: 1em;
}

.feedback {
  transition: all 0.6s;
  opacity: 0;
  transition-timing-function: ease-in-out;
  text-align: center;
  padding: 0 20px;
  max-height: 0;
  line-height: 1.5em;
}

.feedback.expand {
  padding: 20px;
  max-height: 90vh;
  opacity: 1;
}
</style>

<style lang="scss">
.feedback {
  .note {
    color: var(--hsblue);
  }
}
</style>
